/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prop-types */
import React, { useEffect, useCallback } from "react";
import { Card, InlineGrid, Text, Box, Badge } from "@shopify/polaris";
import { useSelector, useDispatch } from "react-redux";
import { fethDebtInvoices, setDestroy } from "./debt-invoices-container.slice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Currency from "components/format/currency/currency";
import Invoices from "components/invoices/Invoices";

function DebtInvoicesContainer() {
  const { totalDebt, totalOverdueDebt, debtInvoices } = useSelector(
    (state) => state.debtInvoicesReducer
  );

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { companyId } = useParams();

  const loadCustomerData = useCallback(
    (companyId) => {
      dispatch(fethDebtInvoices(companyId));
    },
    [dispatch]
  );

  useEffect(() => {
    loadCustomerData(companyId);

    return () => {
      dispatch(setDestroy(true));
    };
  }, [companyId, loadCustomerData]);

  if (!companyId || !debtInvoices) {
    return null;
  }

  return (
    <Card>
      <InlineGrid columns="1fr auto">
        <Text as="h2" variant="headingMd">
          {t("debt_invoices")}
        </Text>
        <div>
          <span>
            {t("debt")}:
            <Badge>
              <Currency value={totalDebt} isSymbol={true} />
            </Badge>
          </span>
          <span>
            {t("overdue_debt")}:
            <Badge>
              <Currency value={totalOverdueDebt} isSymbol={true} />
            </Badge>
          </span>
        </div>
      </InlineGrid>
      <Box paddingBlockStart="200">
        <Invoices invoices={debtInvoices} />
      </Box>
    </Card>
  );
}

export default DebtInvoicesContainer;
