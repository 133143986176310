import React, { useState, useEffect, useCallback } from "react";
import {
  Autocomplete,
  TextField,
  ResourceList,
  ResourceItem,
  Text,
  InlineGrid,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import Currency from "components/format/currency/currency";
import { useParams } from "react-router-dom";
import { fetch } from "containers/products/products.service";
import debounce from "lodash/debounce";
import { Product } from "types/Product";

const DEBOUNCE_DELAY = 300;


interface ProductsSelectProps {
  onChange: (products: Product[]) => void;
  initialProducts?: Product[];
  disabled?:boolean;
}

const ProductsSelect: React.FC<ProductsSelectProps> = ({
  onChange,
  initialProducts = [],
  disabled=false
}) => {
  const [selectedProducts, setSelectedProducts] =
    useState<Product[]>(initialProducts);
  const [searchResults, setSearchResults] = useState<Product[]>([]);
  const [query, setQuery] = useState("");
  const [defaultList, setDefaultList] = useState<Product[]>([]);
  const { companyId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    const isValid = selectedProducts.every(
      (product) => product.quantity && product.price
    );
    if (isValid) {
      onChange(selectedProducts);
    }
  }, [selectedProducts, onChange]);

  useEffect(() => {
    const fetchInitialProducts = async () => {
      if (companyId) {
        const results: Product[] | undefined = await fetchProducts(companyId);
        setDefaultList(results || []);
        setSearchResults(results || []);
      }
    };

    fetchInitialProducts();
  }, [companyId]);

  const handleSearchChange = useCallback(
    debounce(async (value: string) => {
      if (value.length > 0 && companyId) {
        const results: Product[] | undefined = await searchProducts(
          companyId,
          value
        );
        setSearchResults(results || defaultList);
      } else {
        setSearchResults(defaultList);
      }
    }, DEBOUNCE_DELAY),
    [companyId]
  );

  const onSearchInputChange = useCallback(
    (value: string) => {
      setQuery(value);
      if (value === "") {
        setSearchResults(defaultList);
      } else {
        handleSearchChange(value);
      }
    },
    [handleSearchChange, defaultList]
  );

  const handleAddProduct = useCallback((product: Product) => {
    setSelectedProducts((prev) => {
      if (prev.some((p) => p.product === product.product)) {
        return prev; // Product already selected, do not add
      }
      return [...prev, { ...product, quantity: 1, price: product.price }];
    });
  }, []);

  const handleRemoveProduct = useCallback((productId: string) => {
    setSelectedProducts((prev) =>
      prev.filter((product) => product.product !== productId)
    );
  }, []);

  const handleQuantityChange = useCallback(
    (productId: string, quantity: number | "") => {
      setSelectedProducts((prev) =>
        prev.map((product) =>
          product.product === productId ? { ...product, quantity } : product
        )
      );
    },
    []
  );

  const handlePriceChange = useCallback((productId: string, price: number) => {
    setSelectedProducts((prev) =>
      prev.map((product) =>
        product.product === productId ? { ...product, price } : product
      )
    );
  }, []);

  const handleNotesChange = useCallback(
    (productId: string, notes: string | "") => {
      setSelectedProducts((prev) =>
        prev.map((product) =>
          product.product === productId ? { ...product, notes } : product
        )
      );
    },
    []
  );

  const renderProductItem = useCallback(
    (product: Product) => {
      const quantity = product.quantity.toString();
      return (
        <ResourceItem
          key={product.product}
          id={product.product}
          onClick={() => {}}
          accessibilityLabel={`View details for ${product.title}`}
          shortcutActions={[
            {
              content: t("remove"),
              onAction: () => handleRemoveProduct(product.product),
            },
          ]}
        >
          <Text as="p" fontWeight="bold">
            {product.title}
          </Text>
          <InlineGrid gap="400" columns={4}>
            <TextField
              label={t("quantity")}
              value={quantity}
              autoComplete="off"
              onChange={(value) => {
                const parsedValue = parseInt(value, 10);
                if (value && !isNaN(parsedValue)) {
                  handleQuantityChange(product.product, parsedValue);
                } else {
                  handleQuantityChange(product.product, "");
                }
              }}
              disabled={disabled}
            />
            <TextField
              label={t("price")}
              type="text"
              autoComplete="off"
              value={product.price ? product.price.toString() : ""}
              onChange={(value) =>
                handlePriceChange(product.product, parseFloat(value))
              }
              disabled={disabled}
            />
            <div>
              <Text variant="bodyMd" as="p">
                {t("totalPrice")}
              </Text>
              <Currency
                isDecimal={true}
                value={(parseInt(quantity) || 0) * product.price}
                isSymbol={true}
              />
            </div>
            <TextField
              label={t("notes")}
              type="text"
              autoComplete="off"
              value={product.notes || ""}
              onChange={(value) => handleNotesChange(product.product, value)}
              disabled={disabled}
            />
          </InlineGrid>
        </ResourceItem>
      );
    },
    [t, handleRemoveProduct, handleQuantityChange, handlePriceChange]
  );

  return (
    <>
      <Autocomplete
        options={searchResults.map((product) => ({
          value: product.product,
          label: product.title,
        }))}
        selected={[]}
        onSelect={(selected) => {
          const product = searchResults.find((p) => p.product === selected[0]);
          if (product) handleAddProduct(product);
        }}
        textField={
          <Autocomplete.TextField
            label={t("search_products")}
            value={query}
            autoComplete="off"
            onChange={onSearchInputChange}
            disabled={disabled}
          />
        }
      />
      <ResourceList items={selectedProducts} renderItem={renderProductItem} />
    </>
  );
};

// Simulated AJAX search function
const searchProducts = async (
  companyId: string,
  query: string
): Promise<Product[] | undefined> => {
  const filter = { textSearch: query };
  try {
    const { productsCollection } = await fetch(companyId, filter);
    return productsCollection.map((product: any) => ({
      product: product.id,
      value: product.id,
      title: product.title,
      quantity: 0,
      price: product.price,
    }));
  } catch (e) {
    return undefined;
  }
};

const fetchProducts = async (
  companyId: string
): Promise<Product[] | undefined> => {
  try {
    const { productsCollection } = await fetch(companyId);
    return productsCollection.map((product: any) => ({
      product: product.id,
      value: product.id,
      title: product.title,
      quantity: 0,
      price: product.price,
    }));
  } catch (e) {
    return undefined;
  }
};

export default ProductsSelect;
