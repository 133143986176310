import { Navigate, Outlet, useParams } from "react-router-dom";
import { useAppSelector } from "hooks";


const ProtectedRoute = (props) => {
    const { companyId } = useParams();
    const {
        role,
    } = useAppSelector((state) => state.authReducer);

    if (role?.permissionsgroups?.includes('Master')) {
        return <Outlet {...props} />;
    }
    else {
        return <Navigate to={`/app/${companyId}`} replace />
    }


};

export default ProtectedRoute;