import { useEffect, useState, useCallback } from "react";
import _ from "lodash";
import {
  Combobox,
  LegacyStack,
  Tag,
  Listbox,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { fetchUsersPermissionsGroups } from "services/users-permissions-groups.service";

interface PermissionGroup {
  label: string;
  value: string;
}

interface PermissionsGroupsSelectProps {
  value: string[];
  onChange: (selected: string[]) => void;
}

const PermissionsGroupsSelect: React.FC<PermissionsGroupsSelectProps> = ({
  value,
  onChange,
}) => {
  const { t } = useTranslation();
  const [collection, setCollection] = useState<PermissionGroup[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { companyId } = useParams<{ companyId: string }>();
  const [error, setError] = useState<string>("");
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [suggestion, setSuggestion] = useState<string>("");

  useEffect(() => {
    setSelectedTags(value)
  },[JSON.stringify(value)])

  const handleMultiSelectChange = useCallback(
    (selected: string | undefined) => {
      if (selected) {
        const updatedSelection = selectedTags.includes(selected)
          ? selectedTags.filter((tag) => tag !== selected)
          : [...selectedTags, selected];
        setSelectedTags(updatedSelection);
        setSuggestion("");
        onChange(updatedSelection);
      }
    },
    [selectedTags, onChange]
  );

  const handleRemoveTag = useCallback(
    (tag: string) => () => {
      const updatedSelection = selectedTags.filter((item) => item !== tag);
      setSelectedTags(updatedSelection);
      onChange(updatedSelection);
    },
    [selectedTags, onChange]
  );

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const fetchedCollection = await fetchUsersPermissionsGroups(companyId);
        const dataCollection = _.map(fetchedCollection, (row) => ({
          label: row.name,
          value: row.id,
        }));
        dataCollection.unshift({ label: " - ", value: "" });
        setCollection(dataCollection);
      } catch (e) {
        setError(t("fetch_error") || "Failed to fetch data");
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [companyId]);

  const optionsMarkup = collection.map((option, index) => {
    const isSelected = selectedTags.includes(option.value);
    return (
      <Listbox.Option
        key={index}
        value={option.value}
        selected={isSelected}
        accessibilityLabel={option.label}
      >
        <Listbox.TextOption selected={isSelected}>
          {option.label}
        </Listbox.TextOption>
      </Listbox.Option>
    );
  });

  return (
    <div>
      <Combobox
        allowMultiple
        activator={
          <Combobox.TextField
            label={t("permission_group")}
            suggestion={suggestion}
            error={error}
            autoComplete=""
            verticalContent={
              <LegacyStack spacing="extraTight">
                {selectedTags.map((tag, index) => (
                  <Tag key={index} onRemove={handleRemoveTag(tag)}>
                    {collection.find((item) => item.value === tag)?.label}
                  </Tag>
                ))}
              </LegacyStack>
            }
          />
        }
      >
        <Listbox onSelect={handleMultiSelectChange}>{optionsMarkup}</Listbox>
      </Combobox>
    </div>
  );
};

export default PermissionsGroupsSelect;
