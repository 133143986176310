import React from "react";
import {
  Card,
  Text,
  TextContainer,
  InlineStack,
  Button,
  TextField,
} from "@shopify/polaris";
import { EditIcon } from "@shopify/polaris-icons";
import PrettyDate from "components/format/PrettyDate";
import { Comment } from "types/Comment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";


interface CommentListProps {
  comments: Comment[];
  targetComment: Comment | null;
  newComment: string;
  isLoading: string | undefined;
  onEdit: (comment: Comment | null) => void;
  onSave: (commentId: string) => void;
  onCancel: () => void;
  onNewCommentChange: (value: string) => void;
}

const CommentList: React.FC<CommentListProps> = ({
  comments,
  targetComment,
  newComment,
  isLoading,
  onEdit,
  onSave,
  onCancel,
  onNewCommentChange,
}) => {
  const { t } = useTranslation();
  const { session } = useSelector((state: any) => state.authReducer);

  return (
    <>
      {comments.map((comment) => (
        <div className="cmt-marginBottom_10" key={comment.id}>
          {targetComment?.id === comment.id ? (
            <TextField
              label={comment.user.name}
              id="comment"
              name="comment"
              multiline={1}
              value={newComment}
              onChange={onNewCommentChange}
              autoComplete="off"
            />
          ) : (
            <Card>
              <TextContainer>
                <InlineStack wrap={false} align="space-between">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Text as="span"> {comment.user.name}</Text>
                    <Text as="span">
                      <PrettyDate
                        dateString={comment.createdAt}
                        showTime={true}
                      />
                    </Text>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    {comment.createdAt !== comment.updatedAt && (
                      <Text as="span">
                        ({t("last_edit")}:{" "}
                        <PrettyDate
                          dateString={comment.updatedAt}
                          showTime={true}
                        />
                        )
                      </Text>
                    )}
                    {session?.id === comment.user.id && (
                      <Button
                        icon={EditIcon}
                        onClick={() => onEdit(comment)}
                        accessibilityLabel="Edit comment"
                      />
                    )}
                  </div>
                </InlineStack>
                <TextField
                  label="comment box"
                  labelHidden
                  id="comment_view"
                  name="comment_view"
                  multiline={1}
                  value={comment.comment}
                  autoComplete="off"
                  readOnly
                />
              </TextContainer>
            </Card>
          )}
          {targetComment?.id === comment.id &&
            session?.id === comment.user.id && (
              <div className="cmt-marginTop_5">
                <InlineStack wrap={false} align="start" gap="300">
                  <Button
                    loading={isLoading === "update"}
                    onClick={() => onSave(comment.id)}
                    disabled={!newComment}
                  >
                    {t("save")}
                  </Button>
                  <Button onClick={onCancel}>{t("cancel")}</Button>
                </InlineStack>
              </div>
            )}
        </div>
      ))}
    </>
  );
};

export default CommentList;
