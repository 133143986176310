/* eslint-disable no-useless-computed-key */
import { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { DropZone, Button } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setModalxComponent,
  setModalxComponentProps,
} from "components/modalx/modalx.slice";
import { upload } from "services/upload-file.service";
import { patchFile } from "services/upload-file.service";

function UploadFile({
  onChange,
  subject,
  subjectId,
  categoryId,
  isCustomerFormRequired,
  parentFileId,
  categoryType,
}) {
  const dispatch = useDispatch();
  const { companyId, projectId, customerId } = useParams();

  const [openFileDialog, setOpenFileDialog] = useState(false);
  const { t } = useTranslation();

  const handleDropZoneDrop = useCallback(
    // eslint-disable-next-line no-unused-vars
    async (acceptedFiles) => {
      const uploadedFiles = [];
      try {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < acceptedFiles.length; i++) {
          // eslint-disable-next-line no-await-in-loop
          dispatch(setModalxComponent("Loading"));
          const { data } = await upload(acceptedFiles[i], companyId);
          if(subject == "customer" && !parentFileId && categoryId){
            await patchFile(
              { id: data, subject, categories: parentFileId ? null : categoryId ? [categoryId] : [] },
              companyId
            );
          }else if (subject == "customer" && !parentFileId){
            await patchFile(
              { id: data, subject, customer:[subjectId], categories: parentFileId ? null :categoryId ? [categoryId] : [] },
              companyId
            );
          }
          else if (!isCustomerFormRequired){
            await patchFile(
              { id: data, subject, subjectId, categories: parentFileId ? null :categoryId ? [categoryId] : [] },
              companyId
            );
          }
          uploadedFiles.push(data);
        }
        if (uploadedFiles.length === 1) {
          dispatch(setModalxComponent("FileForm"));
          dispatch(
            setModalxComponentProps({
              fileId: uploadedFiles[0],
              customerId,
              companyId,
              projectId,
              categoryId,
              isCustomerFormRequired,
              parentFileId,
              categoryType,
              isCategoryRequired: parentFileId ? false : true
            })
          );
        }
      } catch (e) {
        console.log(e);
      }
    },
    [companyId, dispatch, subject, subjectId]
  );
  const toggleOpenFileDialog = useCallback(
    // eslint-disable-next-line no-shadow
    () => setOpenFileDialog((openFileDialog) => !openFileDialog),
    []
  );

  return (
    <>
      <Button onClick={toggleOpenFileDialog}>{t("upload")}</Button>
      <div style={{ display: "none" }}>
        <DropZone
          allowMultiple={false}
          openFileDialog={openFileDialog}
          onDropAccepted={handleDropZoneDrop}
          onFileDialogClose={toggleOpenFileDialog}
        >
          <DropZone.FileUpload />
        </DropZone>
      </div>
    </>
  );
}

export default UploadFile;

UploadFile.propTypes = {
  onChange: PropTypes.func.isRequired,
  isFileInputs: PropTypes.bool,
  subject: PropTypes.string,
  isCustomerFormRequired: PropTypes.bool,
  parentFileId: PropTypes.string,
};

UploadFile.defaultProps = {
  isCustomerFormRequired: false,
  parentFileId: null,
};
