export interface Comment {
    id: string;
    comment: string;
    user: {
        id: string;
        name: string;
        email: string;
        externalCode: string;
        isDisabled: boolean;
        phone: string;
        createdAt: string;
        updatedAt: string;
    };
    createdAt: string;
    updatedAt: string;
}


export const CommentTypes = {
    Files: 'files',
    Projects: 'projects'
}