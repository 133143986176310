import axios from "axios";
import { API_URL } from "constants";

export const createUsersPermissionsGroups = async (formData, companyId) => {
  const url = `${API_URL}/${companyId}/usersgroups`;
  return axios.post(url, formData).then((response) => response.data);
};

export const updateUsersPermissionsGroups = async (formData, companyId) => {
  const url = `${API_URL}/${companyId}/usersgroups/${formData.id}`;
  return axios.patch(url, formData).then((response) => response.data);
};

export const fetchUsersPermissionsGroupsById = async (companyId, id) => {
  const url = `${API_URL}/${companyId}/usersgroups/${id}`;
  return axios.get(url).then((response) => response.data);
};

export const fetchUsersPermissionsGroups = async (companyId) => {
  const url = `${API_URL}/${companyId}/usersgroups`;
  return axios.get(url).then((response) => response.data);
};
