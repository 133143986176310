import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ResourceList, ResourceItem, Text, Button, Icon, Badge, Card } from "@shopify/polaris";
import { useParams } from "react-router-dom";
import {
  setModalxComponent,
  setModalxComponentProps,
  setModalxStyle
} from "components/modalx/modalx.slice";
import { API_URL } from "../../constants";
import PrettyDate from "components/format/PrettyDate";
import {
  EditIcon,
  ViewIcon,
  CircleDownIcon,
} from '@shopify/polaris-icons';
import { hasPdfExtension } from "utils/helperFunctions";
import "./styles.scss";
import { useNavigate } from "react-router-dom";

function Files({ files }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { companyId } = useParams();
  const navigate = useNavigate();

  const handleOnClick = (fileId) => {
    dispatch(setModalxComponent("FileForm"));
    dispatch(
      setModalxComponentProps({
        fileId,
        companyId,
      })
    );
  };

  const handlePDFModal = (id) => {
    dispatch(setModalxComponent("PdfView"));
    dispatch(setModalxStyle("large"))
    dispatch(
      setModalxComponentProps({
        id,
        companyId,
      })
    );
  };

  return (
    <ResourceList
      resourceName={{ singular: t("file"), plural: t("files") }}
      items={files}
      renderItem={(item) => {
        const { id, createdAt, title, name, category, user, series, number, categories, filesTemplateCustomField, isApproved, fileId } =
          item;
        const downloadUrl = `${API_URL}/${companyId}/files/download/${id}`;
        const categoryId = categories && categories.length ? categories[0].id : null;
        const fileViewUrl = categoryId ? `/app/${companyId}/documents/${categoryId}/${id}` : null;
        const isApprovalRequired = filesTemplateCustomField?.templateEntity?.isApprovalRequired;
        return (
          <ResourceItem id={id} onClick={() => fileViewUrl ? navigate(fileViewUrl) : ''}>
            <div className="flex justify-space content_gap">
              <div>
                <div className="content_break">
                  <Text variant="bodyMd" fontWeight="bold" as="h3" >
                    [{category?.title || "-"}] - {name || title}
                  </Text>
                </div>
                <div className="content_break content_margin">
                  <Text variant="bodyMd" as="p">
                    {t("series")} : {series?.title || "-"}-{number || "-"}
                  </Text>
                </div>
                <PrettyDate dateString={createdAt} showTime={true} /> {user?.name}
                {isApprovalRequired && !isApproved &&
                  <div style={{marginTop:'8px'}}>
                    <Badge tone="attention">{t('approval_required')}</Badge>
                  </div>
                }
              </div>
              <div className="layout-column buttons_gap">
                <Button onClick={(event) => {
                  event.stopPropagation();
                  handleOnClick(id);
                }}>
                  <Icon
                    source={EditIcon}
                    tone="base"
                    accessibilityLabel={t('edit')}
                  />
                </Button>
                <Button 
                  disabled={hasPdfExtension(title) && fileId ? false : true} 
                  onClick={(event) => {
                  event.stopPropagation(); // Prevent parent click
                  handlePDFModal(id);
                }}>
                  <Icon
                    source={ViewIcon}
                    tone="base"
                    accessibilityLabel={t('view')}
                  />
                </Button>
                <Button 
                  url={downloadUrl} 
                  target="_blank" 
                  onClick={(event) => {
                    event.stopPropagation(); 
                  }}
                  disabled={!fileId}
                  >
                  <Icon
                    source={CircleDownIcon}
                    tone="base"
                    accessibilityLabel={t('download')}
                  />
                </Button>
              </div>
            </div>
          </ResourceItem>
        );
      }}
    />
  );
}

export default Files;

Files.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  files: PropTypes.array.isRequired,
  isCatRequired: PropTypes.bool,
};

Files.defaultProps = {
  isCatRequired: false,
};
