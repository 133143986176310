import { Listbox, Combobox, Icon, LegacyStack, Tag } from "@shopify/polaris";
import { SearchIcon } from "@shopify/polaris-icons";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  fetch as fetchCustomers,
  fetchById as fetchCustomerById,
} from "features/customers/customers.service"; // Ensure fetchCustomerById is implemented in your service
import { useState, useCallback, useEffect } from "react";

import { Option } from "types/Options";


const CustomerSelect = ({
  onChange,
  value,
  isRequired = false,
  allowMultiple = false,
}: {
  onChange: any;
  value: string | string[] | undefined;
  isRequired: boolean;
  allowMultiple: boolean;
}) => {
  const { t } = useTranslation();
  const { companyId } = useParams();

  const [selectedOptions, setSelectedOptions] = useState<Option[]>(
    []
  );
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<Option[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchAndSetOptions = useCallback(
    async (searchText: string) => {
      setLoading(true);
      try {
        const { customersCollection } = await fetchCustomers(companyId, {
          textSearch: searchText,
        });
        setOptions(
          customersCollection.map((customer: any) => ({
            value: customer.id,
            label: `${customer.name} [${customer.externalCode}]`,
          }))
        );
      } finally {
        setLoading(false);
      }
    },
    [companyId]
  );

  const fetchAndSetInitialValue = useCallback(
    async (ids: string | string[]) => {
      const idArray = Array.isArray(ids) ? ids : ids ? [ids] : [];
      if (!idArray.length) return; 
      setLoading(true);
      try {
        const customers = await Promise.all(
          idArray.map((id) => fetchCustomerById(companyId, id))
        );
        const selected = customers.map((customer) => ({
          value: customer.id,
          label: `${customer.name} [${customer.externalCode}]`,
        }));
        setSelectedOptions(selected);
        setOptions(selected);
      } finally {
        setLoading(false);
      }
    },
    [companyId]
  );
  useEffect(() => {
    if (value !== undefined) {
      fetchAndSetInitialValue(value);
    }
  }, [JSON.stringify(value),fetchAndSetInitialValue]); // [value, fetchAndSetInitialValue]

  const handleInputChange = useCallback(
    async (input: string) => {
      setInputValue(input);
      fetchAndSetOptions(input);
    },
    [fetchAndSetOptions]
  );

  const handleSelection = useCallback(
    (selected: string) => {
      if (allowMultiple) {
        if(selectedOptions.find((option:Option) => option.value  === selected)){
          setSelectedOptions(selectedOptions.filter((option:Option) => option.value !== selected))
        }else{
          const findSelectedOption = options.find((option) => option.value === selected)
          if(findSelectedOption) setSelectedOptions((prev:Option[]) => [...prev, findSelectedOption]);
          onChange([...selectedOptions.map((option:Option)=> option.value), selected]);
        }
      } else {
        const findSelectedOption:(Option | undefined) = options.find((option) => option.value === selected)
        if(findSelectedOption) setSelectedOptions([findSelectedOption]);
        onChange([selected]);
      }
    },
    [selectedOptions, allowMultiple, onChange]
  );


  const handleTagRemove = (tagToRemove: Option) => {
    const updatedTags = selectedOptions.filter((tag:Option) => tag.value !== tagToRemove.value);
    setSelectedOptions(updatedTags);
    onChange(updatedTags.map((option:Option) => option.value));
  };

  const optionsMarkup = options.map((option) => (
    <Listbox.Option
      key={option.value}
      value={option.value}
      selected={selectedOptions.filter((entity:Option) => entity.value === option.value).length > 0}
      accessibilityLabel={option.label}
    >
      {option.label}
    </Listbox.Option>
  ));

  const verticalContentMarkup =
  selectedOptions && Array.isArray(selectedOptions) && selectedOptions?.length > 0 ? (
    <LegacyStack spacing="extraTight" alignment="center">
      {selectedOptions.map((tag:Option) => {
        return tag ? (
          <Tag key={tag.value} onRemove={() => handleTagRemove(tag)}>
            {tag.label}
          </Tag>
        ) : null;
      })}
    </LegacyStack>
  ) : null;

  return (
    <div>
      <Combobox
        allowMultiple={allowMultiple}
        activator={
          <Combobox.TextField
            prefix={<Icon source={SearchIcon} />}
            onChange={handleInputChange}
            label={t("customer_select")}
            value={inputValue}
            placeholder={t("customer_search")}
            autoComplete="off"
            requiredIndicator={isRequired}
            error={isRequired && !selectedOptions.length ? t("field_is_required") : ""}
            verticalContent={verticalContentMarkup}
          />
        }
      >
        <Listbox onSelect={handleSelection}>
          {loading ? (
            <Listbox.Loading accessibilityLabel="Loading" />
          ) : (
            <>
              {optionsMarkup}
            </>
          )}
        </Listbox>
      </Combobox>
    </div>
  );
};

export default CustomerSelect;
