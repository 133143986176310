import { useState, useEffect, useCallback } from "react";
import {
  Layout,
  Page,
  Card,
  InlineGrid,
  Text,
  IndexTable,
  BlockStack,
  ButtonGroup,
  Tabs,
} from "@shopify/polaris";
import { useSelector, useDispatch } from "react-redux";
import { fetchCustomer, setDestroy } from "./customer-container.slice";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import CustomerDetails from "components/customer/customer-details/customer-details";
import UploadFile from "features/upload-file/upload-file";
import Products from "components/customer/products/products";
import Files from "features/files/files";
import Invoices from "components/invoices/Invoices";
import BuildPdf from "components/customer/build-pdf/BuildPdf";
import Contacts from "components/contacts/Contacts";
import CustomerQuestionnaire from "components/customer-questionnaire/customer-questionnaire";
import "./style.scss";
import Loading from "components/loading/Loading";
import SalesReturns from "components/sales-returns/SalesReturns";

function CustomerContainer() {
  const { customer, isLoading } = useSelector((state) => state.customerReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { customerId, companyId } = useParams();
  const location = useLocation();

  const loadCustomerData = useCallback(() => {
    dispatch(fetchCustomer({ companyId, customerId }));
  }, [companyId, customerId, dispatch]);

  useEffect(() => {
    loadCustomerData();

    return () => {
      dispatch(setDestroy(true));
    };
  }, [loadCustomerData]);

  // Map the URL path to tab index
  const tabPaths = ["/documents", "/products", "/invoices", "/emailmessages", "/salesreturns"];
  const currentPath = location.pathname.split("/").pop();
  const initialSelectedTab = tabPaths.indexOf(`/${currentPath}`) !== -1 ? tabPaths.indexOf(`/${currentPath}`) : 0;

  const [selected, setSelected] = useState(initialSelectedTab);

  const handleTabChange = (selectedTabIndex) => {
    setSelected(selectedTabIndex);
    const newPath = tabPaths[selectedTabIndex];
    navigate(`/app/${companyId}/customers/${customerId}${newPath}`, { replace: true });
  };

  if (isLoading || !customer) {
    return <Loading/>;
  }

  const finvaldaNode = (() => {
    const rowMarkup = Object.entries(customer.meta).map(
      ([key, value], index) => {
        return (
          <IndexTable.Row id={key} key={key} position={index}>
            <IndexTable.Cell>
              <div>{key}</div>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <div>{value}</div>
            </IndexTable.Cell>
          </IndexTable.Row>
        );
      }
    );

    return (
      <IndexTable
        resourceName={{
          singular: "customer",
          plural: "customers",
        }}
        itemCount={Object.entries(customer.meta).length}
        headings={[{ title: t("title") }, { title: t("value") }]}
        selectable={false}
      >
        {rowMarkup}
      </IndexTable>
    );
  })();

  const handleOnFileChange = (data) => { };

  const tabs = [
    {
      id: t('documents'),
      content: t('documents'),
      accessibilityLabel: t('documents'),
      panelID: t('documents'),
      badge: customer?.files?.length || '0',
    },
    {
      id: t('products'),
      content: t('products'),
      panelID: t('products'),
      badge: customer?.products?.length || '0',
    },
    {
      id: t('invoices'),
      content: t('invoices'),
      panelID: t('invoices'),
      badge: customer?.invoices?.length || '0',
    },
    {
      id: t('email_messages'),
      content: t('email_messages'),
      panelID: t('email_messages'),
      badge: customer?.emailMessages?.length || '0',
    },
    {
      id: t('sales_returns'),
      content: t('sales_returns'),
      panelID: t('sales_returns'),
      badge: customer?.salesReturns?.length || '0',
    },
  ];

  return (
    <Page
      fullWidth
      backAction={{ content: "Customers", url: `/app/${companyId}/customers` }}
      title={`${customer.name} [${customer.externalCode}]`}
      subtitle={customer.address}
    // primaryAction={{ content: "Save" }}
    >
      <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange} />
      <Layout>
        <Layout.Section>
          <BlockStack gap="500">
            {selected === 0 && (
              <Card roundedAbove="sm">
                <div className="flex">
                  <Text as="h2" variant="headingSm">
                    {t("files")}
                  </Text>
                  <div className="flex-grow"></div>
                  <ButtonGroup>
                    <UploadFile onChange={handleOnFileChange} subject="customer" subjectId={customerId} />
                    <BuildPdf customerId={customerId} />
                  </ButtonGroup>
                </div>
                <Files files={customer.files} />
              </Card>
            )}
            {selected === 1 && (
              <Card roundedAbove="sm">
                <InlineGrid columns="1fr auto">
                  <Text as="h2" variant="headingSm">
                    {t("products")}
                  </Text>
                </InlineGrid>
                <Products products={customer.products} />
              </Card>
            )}
            {selected === 2 && (
              <Card roundedAbove="sm">
                <InlineGrid columns="1fr auto">
                  <Text as="h2" variant="headingSm">
                    {t("invoices")}
                  </Text>
                </InlineGrid>
                <Invoices invoices={customer.invoices} />
              </Card>
            )}
            {selected === 3 && (
              <Card roundedAbove="sm">
                <InlineGrid columns="1fr auto">
                  <Text as="h2" variant="headingSm">
                    {t("email_messages")}
                  </Text>
                </InlineGrid>
                
              </Card>
            )}
            {selected === 4 && (
              <Card roundedAbove="sm">
                <InlineGrid columns="1fr auto">
                  <Text as="h2" variant="headingSm">
                    {t("sales_returns")}
                  </Text>
                </InlineGrid>
                <SalesReturns salesReturns={customer.salesReturns} />
              </Card>
            )}
          </BlockStack>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <BlockStack gap="500">
            <CustomerDetails customer={customer} onSubmit={loadCustomerData} />
            <CustomerQuestionnaire
              formKeyCreatedAt={customer.formKeyCreatedAt}
              customerId={customer.id}
              email={customer.email}
              formSignature={customer.formSignature}
            />
            <Contacts contacts={customer.contacts} customer={customer.id} />
            <Card roundedAbove="sm">
              <InlineGrid columns="1fr auto">
                <Text as="h2" variant="headingSm">
                  {t("finvalda")}
                </Text>
              </InlineGrid>
              {finvaldaNode}
            </Card>
          </BlockStack>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export default CustomerContainer;
